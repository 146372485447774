<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-card>
            <h2 class="text-center">充值结果</h2>
            <div style="padding: 0 0 50px;width: 100%">
                        <!-- 有货 -->
                        <div class="content p-t-50 p-b-50">
                            <div class="text-center " style="margin-top:86px">
                                <img src="@/assets/images/stockCar/succeed.png">
                                <div class="f14 m-t-15">恭喜充值成功！</div>
                                <div class="finishOrder m-t-30">当前金额 <span class="c-m f24">￥{{userEntity.userBalance}}</span> </div>
                                <el-button class="m-t-30" @click="$oucy.replace('/acc/wallet')">返回</el-button>
                            </div>
                        </div>
                    </div>
        </el-card>
    </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { balance,userMaterial } from "@/service"
import QRCode from 'qrcodejs2'
export default {
    name: "EditUser",
    components: {},
    data() {
        return {
            userEntity:{}
        }
    },
    mounted() {
       const me = this;
       userMaterial.queryUserMaterial().then(res => {
           me.userEntity = res;
           console.log(me.userEntity);
       });
    },
    methods: {}
}
</script>
<style scoped lang="less">
.btn {
    line-height: 1.2;
    display: inline-block;
    padding: 6px 36px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #EAEAEA;
    position: relative;
    cursor: pointer;
    vertical-align: middle;

    .corner {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    &.select {
        border: 1px solid #2090FF;
        color: #2090ff;

        .corner {
            display: inline-block;
        }
    }

    .btnImg {
        vertical-align: inherit;
    }
}

.btn+.btn {
    margin-left: 3px;
}
</style>